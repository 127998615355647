import React, { useContext, useEffect, useState } from "react";
import {
  Form,
  Button,
  Input,
  Checkbox,
  Select,
  Upload,
  DatePicker,
  Switch,
  Modal,
} from "antd";
import "./edit-fw.scss";
import SortableList, { SortableItem, SortableKnob } from "react-easy-sort";
import Delete from "../../../assets/images/delete.svg";
import DragIndicator from "../../../assets/images/drag_indicator.svg";
import { FaArrowLeftLong } from "react-icons/fa6";
import WorkoutOverview from "../../Groups/workout-overview/workout-overview";
import Edit from "../../../assets/images/edit.svg";
import Close from "../../../assets/images/close.svg";
import Calendar from "../../../assets/images/calendar.svg";
import { useNavigate } from "react-router-dom";
import File from "../../../assets/images/files-folders.svg";
import Plus from "../../../assets/images/plus-red.svg";
import DownArrow from "../../../assets/images/arrows.svg";
import ReactQuill from "react-quill";
import { UserContext } from "../../../context/user-context";
import NewFeatureTask from "../new-feature-task/new-feature-task";
import ExistingFeatureTask from "../existing-feature-task/existing-feature-task";
import {
  EditFeatureWorkoutContext,
  EditFeatureWorkoutContextProvider,
} from "../../../context/challenge-workout-context/edit-fw";
import dayjs from "dayjs";
const dateFormat = "ddd. MMMM D, YYYY h:mm A";

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
const EditFWPage = () => {
  const {
    setImageUrl,
    sponsorsList,
    setSponsor,
    newTaskArray,
    setNewTaskArray,
    setCurrent,
    current,
    loading,
    detail,
    postFeatureWorkout,
  } = useContext(EditFeatureWorkoutContext);
  const { selectedSport, color, lightButtonBackground, darkButtonBackground } =
    useContext(UserContext);
  const [existingTaskModal, setExistingTaskModal] = useState(false);
  const [newTaskModal, setNewTaskModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deletedItem, setDeletedItem] = useState({});
  const [imgSrc, setImgSrc] = useState();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const showExistingTaskModal = () => {
    setExistingTaskModal(true);
  };
  const showNewTaskModal = () => {
    setNewTaskModal(true);
  };
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const handleBeforeUpload = (file) => {
    setImageUrl(file);
    const reader = new FileReader();
    reader.onload = (e) => {
      setImgSrc(reader.result);
    };
    reader.readAsDataURL(file);
    // Prevent upload
    return false;
  };
  const removeImage = () => {
    form.setFieldValue("avatar", "");
    setImageUrl();
    setImgSrc();
  };
  const CustomKnob = React.forwardRef((props, ref) => {
    return (
      <Button
        ref={ref}
        icon={
          <img src={DragIndicator} className="drag-icon" draggable={false} />
        }
        style={{ cursor: "grab" }}
      ></Button>
    );
  });
  const updateState = (index) => (e) => {
    const newArray = newTaskArray.map((item, i) => {
      if (index === i) {
        if (e.target) {
          return {
            ...item,
            [e.target.name]:
              typeof e.target.value === "string"
                ? parseInt(e.target.value)
                : e.target.checked,
          };
        } else {
          if (e === true) {
            setCurrent(index);
          } else {
            setCurrent();
          }
          return { ...item, lock: e };
        }
      } else {
        return item;
      }
    });
    setNewTaskArray(newArray);
  };
  const handleOk = () => {
    let newArray = newTaskArray.filter((e) => e.id !== deletedItem.id);
    setNewTaskArray(newArray);
    setDeletedItem({});
    setDeleteModal(false);
  };
  const handleProcedureContentChange = (content, delta, source, editor) => {
    // setCode(content);
  };
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ font: ["Neue Haas Grotesk Display"] }],
      ["bold", "italic", "underline"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
    ],
  };
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "list",
    "indent",
    "link",
    "image",
    "font",
  ];
  let sponsorOption = [];

  for (let i = 0; i < sponsorsList?.length; i++) {
    sponsorOption.push({
      value: sponsorsList[i].id,
      label: sponsorsList[i].name,
    });
  }
  const onChangeSponsor = (value) => {
    for (let i = 0; i < sponsorsList.length; i++) {
      if (value === sponsorsList[i].id) {
        setSponsor(sponsorsList[i]);
      }
    }
  };
  const onSortEnd = (oldIndex, newIndex) => {
    setNewTaskArray((prevState) => {
      const newItems = [...prevState];
      if (oldIndex > newIndex) {
        for (let i = oldIndex; i >= newIndex; i--) {
          newItems[i].order++;
          newItems[oldIndex].order = newIndex + 1;
        }
      } else if (oldIndex < newIndex) {
        for (let i = oldIndex; i <= newIndex; i++) {
          newItems[i].order--;
          newItems[oldIndex].order = newIndex;
        }
      }
      return newItems.sort((a, b) => a.order - b.order);
    });
  };
  const deleteTask = (item) => {
    setDeletedItem(item);
    setDeleteModal(true);
  };
  useEffect(() => {
    if (detail) {
      setImageUrl(detail.image);
      setImgSrc(detail.image);
      setNewTaskArray(detail?.tasks);
      form.setFieldsValue({
        avatar: detail.image,
        title: detail.title,
        start_date: dayjs(detail.start_date),
        expiry_Date: dayjs(detail.ending_on),
        reward_before: detail.reward_before,
        reward_after: detail.reward_after,
        completion_bonus: detail.completion_bonus,
        sponsor_id: detail.presented_by,
      });
    }
  }, [detail]);
  return (
    <div className="create-new-challenge">
      <div className="heading-row">
        <FaArrowLeftLong
          className="left-arrow"
          onClick={() => navigate("/home/workouts")}
          style={{ stroke: color, color: color }}
        />
        <h1>Edit feature workout</h1>
      </div>
      <Form
        form={form}
        name="dynamic_rule"
        className="challenge-form"
        onFinish={postFeatureWorkout}
      >
        <Form.Item
          {...formItemLayout}
          name="avatar"
          label="Display Photo"
          className="img-div"
          rules={[
            {
              required: true,
              message: "Please upload display",
            },
          ]}
        >
          <div className="image-previewer">
            {!imgSrc ? (
              <Upload
                className="uploader"
                showUploadList={false}
                type="file"
                customRequest={dummyRequest}
                beforeUpload={handleBeforeUpload}
              >
                Upload pic
              </Upload>
            ) : (
              <>
                <img
                  src={imgSrc}
                  alt="avatar"
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                  className="img"
                />
                <Upload
                  className="avatar-uploader"
                  showUploadList={false}
                  type="file"
                  customRequest={dummyRequest}
                  beforeUpload={handleBeforeUpload}
                >
                  <img src={Edit} alt="edit" className="edit"></img>
                </Upload>
                <img
                  src={Close}
                  alt="close"
                  className="delete"
                  onClick={removeImage}
                />
              </>
            )}
            <span
              className="img-type"
              style={{
                position: "absolute",
                bottom: "-55px",
                width: "max-content",
              }}
            >
              Allowed file types: PNG, JPG, JPEG.
            </span>
          </div>
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="title"
          label="Workout Title"
          className="name-row"
          rules={[
            {
              required: true,
              message: "Please enter feature workout title",
            },
          ]}
          style={{ marginTop: 24 }}
        >
          <Input
            placeholder="Please enter workout title"
            className="custom-input"
          />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="completion_bonus"
          label="Completion Bonus"
          className="name-row"
          rules={[
            {
              required: true,
              message: "Please enter completion bonus",
            },
            {
              pattern: new RegExp(/^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/),
              message: "Please enter valid completion bonus",
            },
          ]}
        >
          <Input
            placeholder="Please enter bonus e.g. 100"
            className="add-on-input"
          />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="start_date"
          label="Starting on"
          rules={[
            {
              required: true,
              message: "Please enter start date",
            },
          ]}
        >
          <DatePicker
            showTime
            className={
              "date-picker " +
              (selectedSport === 1 ? "orange-picker" : "blue-picker")
            }
            rootClassName={
              selectedSport === 1 ? "orange-picker" : "blue-picker"
            }
            format={dateFormat}
            suffixIcon={<img src={Calendar} />}
            style={{ height: "38px" }}
            placeholder="Select start date"
          />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="expiry_Date"
          label="Ending on"
          rules={[
            {
              required: true,
              message: "Please enter end date",
            },
          ]}
        >
          <DatePicker
            showTime
            className={
              "date-picker " +
              (selectedSport === 1 ? "orange-picker" : "blue-picker")
            }
            rootClassName={
              selectedSport === 1 ? "orange-picker" : "blue-picker"
            }
            format={dateFormat}
            suffixIcon={<img src={Calendar} />}
            style={{ height: "38px" }}
            placeholder="Select end date"
          />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="reward_before"
          label="Reward before completing the feature workout "
          rules={[
            {
              required: false,
              message: "Please enter reward",
            },
          ]}
        >
          <ReactQuill
            theme="snow"
            modules={modules}
            formats={formats}
            className="custom-toolbar"
            onChange={handleProcedureContentChange}
            placeholder="Rewards before feature workout completion..."
          />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="reward_after"
          label="Reward after completing the feature workout "
          rules={[
            {
              required: false,
              message: "Please enter reward",
            },
          ]}
        >
          <ReactQuill
            theme="snow"
            modules={modules}
            formats={formats}
            className="custom-toolbar"
            onChange={handleProcedureContentChange}
            placeholder="Rewards after feature workout completion..."
          />
        </Form.Item>
        <h2>Sponsor Details</h2>
        <Form.Item
          {...formItemLayout}
          name="sponsor_id"
          label="Presented by"
          className="custom-label"
        >
          <Select
            placeholder="Select Sponsor"
            optionFilterProp="children"
            onChange={onChangeSponsor}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            size={"large"}
            suffixIcon={<img src={DownArrow} />}
            style={{ height: 38, width: "100%", marginTop: 8 }}
            popupClassName={
              "custom-action-select-dropdown " +
              (selectedSport === 1
                ? "orange-theme-select"
                : "blue-theme-select")
            }
            className="custom-select"
            options={sponsorOption}
          />
        </Form.Item>
        <div className="btn-div">
          <Button
            icon={<img src={File} />}
            className="existing-task-btn"
            onClick={showExistingTaskModal}
          >
            Add from existing tasks
          </Button>
          <Button
            icon={
              <img
                src={Plus}
                className={
                  selectedSport === 1 ? "orange-theme-icon" : "blue-theme-icon"
                }
              />
            }
            className="new-task-btn"
            onClick={showNewTaskModal}
            style={{
              borderColor: color,
              background: lightButtonBackground,
              color: color,
            }}
          >
            Create a new task
          </Button>
        </div>
        {newTaskArray && newTaskArray.length > 0 && (
          <div className="workout-overview">
            <div className="total">
              Total Tasks
              <span>
                {newTaskArray &&
                  newTaskArray.filter(
                    (ele, ind) =>
                      ind ===
                      newTaskArray.findIndex((elem) => elem.id === ele.id)
                  ).length}
              </span>
            </div>
            <SortableList
              onSortEnd={onSortEnd}
              className="list"
              draggedItemClassName="dragged"
            >
              {newTaskArray &&
                newTaskArray
                  .filter(
                    (ele, ind) =>
                      ind ===
                      newTaskArray.findIndex((elem) => elem.id === ele.id)
                  )
                  .map((item, index) => {
                    return (
                      <SortableItem key={index}>
                        <div
                          className="overview-card"
                          style={{ listStyleType: "none" }}
                        >
                          <div style={{ display: "flex" }}>
                            <h3 style={{ textTransform: "capitalize" }}>
                              {item.title}
                              {/* ({item.skill_level}){" "} */}
                              {/* {item.score_based_test === true
                                ? "Scored Task"
                                : "Training Task"} */}
                            </h3>
                            <div className="right-div">
                              {item?.updated_at ? (
                                <Button
                                  icon={<img src={Delete} draggable={false} />}
                                  onClick={() => deleteTask(item)}
                                ></Button>
                              ) : ""}
                              <SortableKnob>
                                <CustomKnob />
                              </SortableKnob>
                            </div>
                          </div>
                          <div className="form-data">
                            <div className="reps">
                              <label htmlFor="complete-task">
                                Complete task in
                              </label>
                              <br />
                              <Input
                                addonBefore={"Reps"}
                                defaultValue={item.num_reps}
                                className="reps-input"
                                name={"num_reps"}
                                onChange={updateState(index)}
                                min={1}
                                value={item.num_reps > 0 ? item.num_reps : ""}
                              />
                            </div>
                            <div className="reps">
                              <label htmlFor="complete-task">
                                Legacy Points
                              </label>
                              <br />
                              <Input
                                defaultValue={item.legacy_points}
                                className="reps-input"
                                name={"legacy_points"}
                                onChange={updateState(index)}
                                style={{ marginTop: 8 }}
                                // min={1}
                                value={
                                  isNaN(item.legacy_points) === true
                                    ? 0
                                    : item.legacy_points
                                }
                              />
                            </div>
                            {item.score_based_test === true && (
                              <div className="reps">
                                <label htmlFor="complete-task">
                                  Minimum Score to Pass
                                </label>
                                <br />
                                <Input
                                  defaultValue={item.min_score}
                                  className="reps-input"
                                  name={"min_score"}
                                  onChange={updateState(index)}
                                  min={1}
                                  style={{ marginTop: 8 }}
                                  value={
                                    item.min_score > 0 ? item.min_score : ""
                                  }
                                />
                              </div>
                            )}
                          </div>
                          <div className="extra">
                            <Checkbox
                              className="custom-checkbox"
                              defaultChecked={item.allow_more_reps}
                              checked={item.allow_more_reps}
                              onChange={updateState(index)}
                              name="allow_more_reps"
                            />
                            <span className="allowed">Allowed extra reps.</span>
                            {index !== 0 && (
                              <div className="lock-div">
                                Lock this task
                                <Switch
                                  defaultChecked={item.lock}
                                  checked={index >= current || item.lock}
                                  disabled={index > current}
                                  name="lock"
                                  onChange={updateState(index)}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </SortableItem>
                    );
                  })}
            </SortableList>
          </div>
        )}
        <Form.Item style={{ textAlign: "start", marginBottom: "0" }}>
          <Button
            htmlType="submit"
            type="primary"
            className="discard-btn"
            onClick={() => form.resetFields()}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              margin: "0 8px",
              background: darkButtonBackground,
              cursor: loading ? "not-allowed" : "pointer",
            }}
            loading={loading}
            className="dark-base-btn"
            disabled={loading}
          >
            Update
          </Button>
        </Form.Item>
      </Form>
      {existingTaskModal && (
        <ExistingFeatureTask
          existingTaskModal={existingTaskModal}
          setExistingTaskModal={setExistingTaskModal}
          setNewTaskArray={setNewTaskArray}
          newTaskArray={newTaskArray}
        />
      )}
      <NewFeatureTask
        newTaskModal={newTaskModal}
        setNewTaskModal={setNewTaskModal}
        newTaskArray={newTaskArray}
        setNewTaskArray={setNewTaskArray}
      />
      <Modal
        className="delete-task-modal"
        wrapClassName={
          selectedSport === 1 ? "orange-theme-modal" : "blue-theme-modal"
        }
        open={deleteModal}
        onOk={handleOk}
        onCancel={() => setDeleteModal(false)}
        okText={"Remove"}
        cancelText={"Cancel"}
        width={"608px"}
      >
        <h1>Remove this task</h1>
        <p>Are you sure you want to remove this task? </p>
      </Modal>
    </div>
  );
};
const EditFW = () => {
  return (
    <EditFeatureWorkoutContextProvider>
      <EditFWPage />
    </EditFeatureWorkoutContextProvider>
  );
};
export default EditFW;
